.Home-nav {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}
.Home-Proactive-warp {
    width: 100%;
    background-color: rgba(240, 246, 251, 1);
}
.Home-Proactive,
.Home-Opt360 {
    position: relative;
    margin: 0 auto;
}
.Home-Proactive > div {
    position: absolute;
}
.Home-Proactive-info > div:first-of-type {
    font-weight: bold;
}
.Home-Opt360-warp {
    width: 100%;
}
.Home-Opt360 > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Home-growing-warp {
    background-color: rgba(240, 246, 251, 1);
    text-align: center;
}
.Home-touch-warp {
    background-color: rgba(49, 90, 254, 1);
    text-align: center;
}
.Home-touch-warp > div {
    color: #fff;
}
.bg-stuff-pos,
.proline_stuff {
    position: absolute;
}
.infoPanel-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}