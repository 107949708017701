/* CSS Reset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box; /* Ensure padding and borders are included in element sizes */
}
 
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
 
body {
    line-height: 1;
}
 
ol, ul {
    list-style: none;
}
 
blockquote, q {
    quotes: none;
}
 
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
 
table {
    border-collapse: collapse;
    border-spacing: 0;
}
 
/* Custom Styles */
body {
    background: white;
    color: black;
    font-family: 'Open Sans', sans-serif;
    overflow-x: hidden;
}
 
/* 更多自定义样式 */
a {
    text-decoration: none;
    color: rgba(13, 16, 25, 1)
}

.c-form .ant-form-item .ant-form-item-label >label {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
}
.c-form .ant-input-outlined {
    background-color: rgba(255, 255, 255, 0.10);
    border-color: rgba(255, 255, 255, 0.40);
}
.c-form .ant-input {
    color: #fff;
}

/* 使用CSS类选择器来指定Input组件的placeholder颜色 */
.ant-input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.50); 
  }
   
  .ant-input:-moz-placeholder {
    color: rgba(255, 255, 255, 0.50); /* 老版本的Firefox */
  }
   
  .ant-input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.50); /* 新版本的Firefox */
  }
   
  .ant-input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.50); /* 老版本的IE */
  }