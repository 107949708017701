.Meun-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20%;
    width: 100%;
    height: 100%;
}
.Meun-container > li {
    position: relative;
    font-weight: 500;
    color: #788197;
    cursor: pointer;
    height: 100%;
}
.Meun-container > li:hover {
    color: rgba(255,255,255, .7);
}
.Meun-container > .selected,
.Meun-container > .selected:hover {
    color: #fff;
}
.Meun-container > .Solutions:hover {
}
.Meun-container-drop-panel {
    position: absolute;
    background-color: rgba(18, 23, 35, 1);
}


.SMeun-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 400;
    line-height: normal;
    background-color: rgba(18, 23, 35, 1);
    color: rgba(120, 129, 151, 1);
}
.SMeun-container > div {
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #161B27;
    border-radius: 12px;
    cursor: pointer;
}
.SMeun-item-icon {
    display: block;
    border-radius: 50%;
    background-color: #272D3D;
    text-align: center;
}
.SMeun-container > div:hover {
    background-color: rgba(193, 172, 255, 1);
    color: #fff;
}
.SMeun-container > div:first-of-type:hover {
    background-color: rgba(49, 130, 254, 1);
    color: #fff;
}
.SMeun-container > div:hover:last-of-type {
    background-color: rgba(73, 223, 230, 1);
    color: #fff;
}
.SMeun-container > .SMeun-container-active,
.SMeun-container > .SMeun-container-active:hover {
    background-color: rgba(193, 172, 255, 1);
    font-weight: bold;
    color: #fff;
}
.SMeun-container > .SMeun-container-active:first-of-type,
.SMeun-container > .SMeun-container-active:first-of-type:hover {
    background-color: rgba(49, 130, 254, 1);
    font-weight: bold;
}
.SMeun-container > .SMeun-container-active:last-of-type,
.SMeun-container > .SMeun-container-active:last-of-type:hover {
    background-color: #49DFE6;
    font-weight: bold;
}
.SMeun-container > div:hover >.SMeun-item-icon,
.SMeun-container > .SMeun-container-active > .SMeun-item-icon {
    background-color: #fff;
    color: #fff;
}