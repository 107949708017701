.footer {
    background-color: rgba(13, 16, 25, 1);
}
.footer > div:first-of-type {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.footer-nav {
    color: rgba(255, 255, 255, 1);
}
.email-btn > div {
    background-color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.boottom-nav {
    position: relative;
    background: linear-gradient(to right, #fff, #fff) no-repeat;
    background-size: 0 2px;
    background-position: bottom left 0;
    transition: all 1s;
    cursor: pointer;
}
.boottom-nav:hover {
    background-size: 100% 2px;
}
/* .boottom-nav::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    transition: width 2 ease-out;
}
.boottom-nav:hover::after {
    width: 100%;
} */