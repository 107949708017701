.Banner-container {
    position: relative;
    width: 100%;
    color: #fff;
    background-color: #161B27;
}
.bgLight {
    position: absolute;
}
.Banner-info {
    position: absolute;
}
.Banner-Alert {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba(49, 128, 254, 0.20);
    color: rgba(49, 130, 254, 1);
}
.Banner-word1 {
    font-size: 56px;
}
.Banner-word2 {
    font-weight: bold;
    font-size: 56px;
}
.Banner-word3 {
    color: rgba(255,255,255,0.5);
}
.Banner-opt,
.Banner-opt > div {
    display: flex;
    align-items: center;
    justify-content: center;
}
.Banner-opt  {
    justify-content: flex-start;
}
.Banner-opt > div {
    border: 1px solid rgba(49, 131, 254, 1);
    color: rgba(49, 131, 254, 1);
    font-weight: 600;
    cursor: pointer;
}
.Banner-opt > div:first-of-type {
    background-color: rgba(49, 131, 254, 1);
    color: #fff;
}
.Banner-opt-icon {
    border-radius: 50%;
    background-color: #fff;
    text-align: center;
}
.Banner-opt-in {
    animation: flipInX; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2s; /* don't forget to set a duration! */
    animation-delay: 1s;
}