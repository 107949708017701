.btn-pulse {
    animation: pulse; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1.5s; /* don't forget to set a duration! */
    animation-iteration-count: infinite;
}
.btn-pulse-star1 {
    animation: pulse; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
    animation-iteration-count: infinite;
}
.btn-pulse-star2 {
    animation: pulse; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
    animation-delay: 1s;
    animation-iteration-count: infinite;
}
.flipInX-delay {
    animation: flipInX; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2s; /* don't forget to set a duration! */
    animation-delay: 0.5s;
}

.fadeInRightBig1 {
    animation: fadeInRightBig; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
    animation-delay: 0.3s;
}

.fadeInRightBig2 {
    animation: fadeInRightBig; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
    animation-delay: 0.6s;
}
.fadeInLeftBig1 {
    animation: fadeInLeftBig; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
    animation-delay: 0.3s;
}
.zoomInLeft1 {
    animation: zoomInLeft; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
    animation-delay: 0.5s;
}
.headShake-inft {
    animation: headShake; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2s; /* don't forget to set a duration! */
    animation-delay: 1s;
    animation-iteration-count: infinite;
}
@keyframes routeBase {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}
.routeAni {
    animation: routeBase linear;
    animation-duration: 10s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
}
.routeAni2 {
    animation: routeBase linear;
    animation-duration: 10s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
}
.routeAni-not-linear {
    animation: routeBase;
    animation-duration: 10s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
}
.sharkX-inft {
    animation: shakeX; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 10s; /* don't forget to set a duration! */
    animation-delay: 1s;
    animation-iteration-count: infinite;
}
.slideInLeft1 {
    animation: slideInLeft;
    animation-duration: 1s;
    animation-delay: 0.3s;
}
.slideInLeft2 {
    animation: slideInLeft;
    animation-duration: 1s;
    animation-delay: 0.6s;
}
.slideInLeft3 {
    animation: slideInLeft;
    animation-duration: 1s;
    animation-delay: 0.9s;
}
.slideInRight1 {
    animation: slideInRight;
    animation-duration: 1s;
    animation-delay: 0.3s;
}
.slideInRight2 {
    animation: slideInRight;
    animation-duration: 1s;
    animation-delay: 0.6s;
}
.fadeInLeft1 {
    animation: fadeInLeft;
    animation-duration: 1s;
    animation-delay: 0.3s;
}
.fadeInLeft2 {
    animation: fadeInLeft;
    animation-duration: 1s;
    animation-delay: 0.6s;
}

custom-flip-0 {
    animation: flipInY;
    animation-duration: 1s;
}
custom-flip-1 {
    animation: flipInY;
    animation-duration: 1s;
    animation-delay: 0.3s;
}
custom-flip-2 {
    animation: flipInY;
    animation-duration: 1s;
    animation-delay: 0.6s;
}
custom-flip-3 {
    animation: flipInY;
    animation-duration: 1s;
}
custom-flip-4 {
    animation: flipInY;
    animation-duration: 1s;
    animation-delay: 0.3s;
}
custom-flip-5 {
    animation: flipInY;
    animation-duration: 1s;
    animation-delay: 0.6s;
}



.custom-card,
.custom-card-img > img {
    transition: 0.5s;
}
.custom-card-img:hover > img,
.custom-card:hover {
    transform: scale(1.05);
}

.custom-card-no-img {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
  }
   
.custom-card-no-img:hover {
    transform: translateY(-6px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, .7);
}

.custom-btn-hover,
.custom-btn-hover-img {
    transition: transform 0.3s;
}
.custom-btn-hover:hover {
    transform: translateY(-6px);
}
.custom-btn-hover:hover .custom-btn-hover-img  {
    transform: rotate(45deg);
}