
.pramiry {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(49, 131, 254, 1);
    font-weight: 600;
    cursor: pointer;
    background-color: rgba(49, 131, 254, 1);
    color: #fff;
}

.animate__animated.btn-pulse > .pramiry:hover,
.animate__animated.btn-pulse > .touch-btn:hover  {
    /* transform: scale(1); */
}
.pramiry:hover,
.touch-btn:hover  {
    /* transform: scale(1.1); */
}
.touch-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    cursor: pointer;
}
.touch-btn > span {
    color: #000; 
}