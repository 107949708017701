.Nav-container {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-image: url('../../static/images/opt_home_titlebg.png');
    
}
.Nav-container-child {
    width: 50%;
    height: 100%;
}
.Nav-container_left {
}
.Nav-container_right {
}
.App-logo {
    margin-left: 30%;
}