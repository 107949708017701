.r-Banner-container {
    position: relative;
    width: 100%;
    color: #161B27;
    background-color: #fff;
}
.Banner-info {
    position: absolute;
}
.Banner-word1,
.Banner-word2 {
    font-weight: bold;
}
.Banner-word3 {
    color: rgba(22,27,39,0.5);
}
.Banner-opt,
.Banner-opt > div {
    display: flex;
    align-items: center;
    justify-content: center;
}
.Banner-opt  {
    justify-content: flex-start;
}
.Banner-opt > div {
    border: 1px solid rgba(49, 131, 254, 1);
    color: rgba(49, 131, 254, 1);
    font-weight: 600;
    cursor: pointer;
}
.Banner-opt > div:first-of-type {
    background-color: rgba(49, 131, 254, 1);
    color: #fff;
}
.Banner-opt-icon {
    border-radius: 50%;
    background-color: #fff;
    text-align: center;
}
.word4-dont {
    display: inline-block;
    margin-right: 8px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: rgba(22, 27, 39, 1);
}
.Banner-word4 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}