.c-swiper {
    position: relative;
    margin: 0 auto;
}
.card {
    position: relative;
    text-align: left;
    background-color: #fff;
}
.card-footer {
    width: 100%;
    position: absolute;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.c-swiper-btn {
    position: absolute;
    background-color: rgba(220, 239, 255, 1);
    color: rgba(49, 90, 254, 1);
    text-align: center;
    cursor: pointer;
}